define("frontend-cp/components/ko-agent-content/layout/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "container": "ko-agent-content_layout__container_15wz2w",
    "content": "ko-agent-content_layout__content_15wz2w",
    "sidebar": "ko-agent-content_layout__sidebar_15wz2w",
    "fields": "ko-agent-content_layout__fields_15wz2w",
    "spacer": "ko-agent-content_layout__spacer_15wz2w",
    "submit-button": "ko-agent-content_layout__submit-button_15wz2w",
    "heading": "ko-agent-content_layout__heading_15wz2w",
    "timeline": "ko-agent-content_layout__timeline_15wz2w",
    "reply-area": "ko-agent-content_layout__reply-area_15wz2w"
  };
});