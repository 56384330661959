define("frontend-cp/components/ko-timeline-2/list/item/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "mention-wormhole": "ko-timeline-2_list_item__mention-wormhole_1oksrd",
    "item": "ko-timeline-2_list_item__item_1oksrd",
    "joined-with-previous": "ko-timeline-2_list_item__joined-with-previous_1oksrd",
    "joined-with-previous-note": "ko-timeline-2_list_item__joined-with-previous-note_1oksrd",
    "body": "ko-timeline-2_list_item__body_1oksrd",
    "side-column": "ko-timeline-2_list_item__side-column_1oksrd",
    "header-container": "ko-timeline-2_list_item__header-container_1oksrd",
    "note-summary": "ko-timeline-2_list_item__note-summary_1oksrd",
    "note": "ko-timeline-2_list_item__note_1oksrd",
    "case": "ko-timeline-2_list_item__case_1oksrd",
    "pinned": "ko-timeline-2_list_item__pinned_1oksrd",
    "content": "ko-timeline-2_list_item__content_1oksrd",
    "joined-with-next": "ko-timeline-2_list_item__joined-with-next_1oksrd",
    "post": "ko-timeline-2_list_item__post_1oksrd",
    "post--replying": "ko-timeline-2_list_item__post--replying_1oksrd",
    "no-menus-open": "ko-timeline-2_list_item__no-menus-open_1oksrd",
    "menu-open": "ko-timeline-2_list_item__menu-open_1oksrd",
    "header": "ko-timeline-2_list_item__header_1oksrd",
    "title": "ko-timeline-2_list_item__title_1oksrd",
    "time": "ko-timeline-2_list_item__time_1oksrd",
    "creator": "ko-timeline-2_list_item__creator_1oksrd",
    "subtitle": "ko-timeline-2_list_item__subtitle_1oksrd",
    "iframe-content": "ko-timeline-2_list_item__iframe-content_1oksrd",
    "html-content": "ko-timeline-2_list_item__html-content_1oksrd",
    "waiting": "ko-timeline-2_list_item__waiting_1oksrd",
    "sending": "ko-timeline-2_list_item__sending_1oksrd",
    "failed": "ko-timeline-2_list_item__failed_1oksrd",
    "attachments-header": "ko-timeline-2_list_item__attachments-header_1oksrd",
    "joined-with-next-note": "ko-timeline-2_list_item__joined-with-next-note_1oksrd",
    "side-time": "ko-timeline-2_list_item__side-time_1oksrd",
    "menu": "ko-timeline-2_list_item__menu_1oksrd",
    "menu-wrapper": "ko-timeline-2_list_item__menu-wrapper_1oksrd",
    "hidden-item": "ko-timeline-2_list_item__hidden-item_1oksrd",
    "menu-item": "ko-timeline-2_list_item__menu-item_1oksrd",
    "yellow": "ko-timeline-2_list_item__yellow_1oksrd",
    "processing": "ko-timeline-2_list_item__processing_1oksrd",
    "quote-icon": "ko-timeline-2_list_item__quote-icon_1oksrd",
    "status": "ko-timeline-2_list_item__status_1oksrd",
    "status-separator": "ko-timeline-2_list_item__status-separator_1oksrd",
    "pinned-by": "ko-timeline-2_list_item__pinned-by_1oksrd",
    "pinned-by-avatar": "ko-timeline-2_list_item__pinned-by-avatar_1oksrd",
    "separator": "ko-timeline-2_list_item__separator_1oksrd",
    "role": "ko-timeline-2_list_item__role_1oksrd",
    "extended-info": "ko-timeline-2_list_item__extended-info_1oksrd",
    "metadata-page-url": "ko-timeline-2_list_item__metadata-page-url_1oksrd",
    "client-failed-message": "ko-timeline-2_list_item__client-failed-message_1oksrd"
  };
});