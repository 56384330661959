define('frontend-cp/components/ko-timeline-2/list/ai-suggestion-bubble/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var guidFor = Ember.guidFor;


  var initialTextLength = 250;

  exports.default = Component.extend({

    metrics: service(),

    tagName: '',
    // Attributes
    text: '',
    htmlContent: null,
    isExpanded: false,
    uniqueId: null,
    setReplyContent: function setReplyContent() {},
    openReply: function openReply() {},
    sendReply: function sendReply() {},

    // Lifecycle Hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.uniqueId = guidFor(this);
      if (!this.htmlContent) {
        this.set('htmlContent', this.text);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var parent = this.get('parent');
      var event = this.get('event');
      this.get('metrics').trackEvent({
        event: 'Atlas AI - Viewed AI Generated Response',
        caseId: parent && parent.id || null,
        casePostUUID: event && event.get('uuid') || null
      });
    },


    truncatedText: computed('text', 'isExpanded', function () {
      var text = this.get('text');
      if (this.isExpanded) {
        return text;
      } else {
        if (text.length > initialTextLength) {
          return text.slice(0, text.slice(0, initialTextLength).lastIndexOf(' ')) + '...';
        } else {
          return text;
        }
      }
    }),

    showReadMoreButton: computed('text', function () {
      return this.get('text').length > initialTextLength;
    }),

    actions: {
      toggleExpand: function toggleExpand() {
        this.toggleProperty('isExpanded');
      },
      pasteAsIs: function pasteAsIs() {
        var parent = this.get('parent');
        var event = this.get('event');
        this.get('metrics').trackEvent({
          event: 'Atlas AI - Paste as is',
          caseId: parent && parent.id || null,
          casePostUUID: event && event.get('uuid') || null
        });
        this.setReplyContent('' + this.htmlContent);
        this.openReply();
        this.submitReply();
      },
      editResponse: function editResponse() {
        var parent = this.get('parent');
        var event = this.get('event');
        this.get('metrics').trackEvent({
          event: 'Atlas AI - Edit Response',
          caseId: parent && parent.id || null,
          casePostUUID: event && event.get('uuid') || null
        });
        this.setReplyContent('' + this.htmlContent);
      }
    }
  });
});