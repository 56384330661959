define('frontend-cp/components/ko-case-content/cc/component', ['exports', 'ember-service/inject', 'frontend-cp/components/ko-case-content/cc/styles'], function (exports, _inject, _styles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var guidFor = Ember.guidFor;
  var run = Ember.run;
  var bind = Ember.run.bind;
  var on = Ember.on;
  var $ = Ember.$;


  var COLLAPSED_CC_LIMIT = 8;

  exports.default = Component.extend({
    launchDarkly: (0, _inject.default)(),

    tagName: 'div',

    // Attributes
    select: null,
    selected: null,
    onChange: function onChange() {},
    onToggle: function onToggle() {},
    onCCInput: function onCCInput() {},
    isActive: false,
    onSearch: null,

    state: null,

    focusInput: function focusInput(select) {
      $('#' + this.get('inputId')).focus();
    },
    handleOutsideClick: function handleOutsideClick(event) {
      var launchDarkly = this.get('launchDarkly');

      if (launchDarkly.get('release-cc-list-improvements')) {
        var focusIsNotOnCCInput = document.activeElement.id !== this.get('inputId');
        var targetIsNotAChildElement = this.element && !this.element.contains(event.target);
        var isDeletedOptionItem = !document.body.contains(event.target) && $(event.target).parents('.' + _styles.default['option-item']).length;

        if (!isDeletedOptionItem && focusIsNotOnCCInput && targetIsNotAChildElement) {
          // Handling Outside Clicks
          this.send('toggleCC', null);
        }
      } else {
        if (this.element && !this.element.contains(event.target)) {
          // Handling Outside Clicks
          this.send('toggleCC', null);
        }
      }
    },


    setupOutsideClickListener: on('didInsertElement', function () {
      this._clickHandler = bind(this, 'handleOutsideClick');
      return $(document).on('click', this._clickHandler);
    }),

    removeOutsideClickListener: on('willDestroyElement', function () {
      return $(document).off('click', this._clickHandler);
    }),

    // CPs
    inputId: computed(function () {
      return 'ko-' + guidFor(this);
    }),

    viewableSelectedCCs: computed('selected', 'isActive', function () {
      if (this.get('isActive')) {
        return this.get('selected');
      }
      return this.get('selected').slice(0, COLLAPSED_CC_LIMIT);
    }),

    hiddenCCsCount: computed('selected', 'isActive', function () {
      if (this.get('isActive')) return 0;

      return this.get('selected.length') - COLLAPSED_CC_LIMIT;
    }),

    showExpandCCsButton: computed.gt('hiddenCCsCount', 0),

    actions: {
      toggleCC: function toggleCC(e) {
        var launchDarkly = this.get('launchDarkly');

        if (e) {
          e.preventDefault();
        }
        // Event is set to null when it's called through onOutsideClick
        if (this.get('isActive') || !e) {
          if (launchDarkly.get('release-cc-list-improvements')) {
            this.get('onToggle')(false);
            document.body.focus(); // Moving focus out of the input (if it was focused) for IE11 issues. [FT-1748; #2375]
          } else {
            if (!this.get('selected.length')) {
              this.get('onToggle')(false);
              document.body.focus(); // Moving focus out of the input (if it was focused) for IE11 issues. [FT-1748; #2375]
            }
          }
        } else {
          this.get('onToggle')(true);
          run.schedule('afterRender', this, this.focusInput);
        }
      },
      addUser: function addUser(select) {
        var _this = this;

        select.actions.choose(select.searchText.trim());
        run.scheduleOnce('afterRender', function () {
          select.actions.search('');
          _this.focusInput(select);
        });
      },
      onChange: function onChange(emails, select) {
        var _this2 = this;

        this.get('onChange')(emails, select);
        run.scheduleOnce('afterRender', function () {
          _this2.focusInput(select);
        });
      },
      addImmediateCC: function addImmediateCC(e, cc, select) {
        var _this3 = this;

        this.get('onChange')(this.get('selected').addObject(cc), select);
        run.scheduleOnce('afterRender', function () {
          _this3.focusInput(select);
        });
      },
      popCC: function popCC(option) {
        this.get('onChange')(this.get('selected').removeObject(option));
      }
    }
  });
});