define('frontend-cp/components/ko-timeline-2/list/item/email-info-inline/component', ['exports', 'ember-resize/mixins/resize-aware', 'frontend-cp/components/ko-timeline-2/list/item/email-info-inline/styles', 'npm:he'], function (exports, _resizeAware, _styles, _npmHe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var debounce = Ember.run.debounce;
  var scheduleOnce = Ember.run.scheduleOnce;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var filterBy = Ember.computed.filterBy;
  var or = Ember.computed.or;
  var service = Ember.inject.service;


  var RESIZE_DEBOUNCE_INTERVAL = 100;

  exports.default = Component.extend(_resizeAware.default, {
    tagName: 'span',

    // Services
    i18n: service(),
    store: service(),

    // Attributes
    post: null,
    isChevronActive: false,
    resizeWidthSensitive: true,
    othersCount: 0,

    didInsertElement: function didInsertElement() {
      scheduleOnce('afterRender', this, 'handleCCListOverflow', 50);
      this._super.apply(this, arguments);
    },


    // Event Handlers
    didResize: function didResize() {
      debounce(this, 'handleCCListOverflow', RESIZE_DEBOUNCE_INTERVAL);
    },


    // Methods
    handleCCListOverflow: function handleCCListOverflow() {
      var extraOffset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      var element = $(this.element);
      element.find('.' + _styles.default['email-item-cc'] + '.' + _styles.default['no-comma']).removeClass(_styles.default['no-comma']);
      var parentRightEdge = element.parent().position().left + element.parent().width();
      var offsetForTrigger = 100 + extraOffset;

      var childrenVisible = element.find('.' + _styles.default['email-item-cc'] + ':visible');
      var childrenHidden = element.find('.' + _styles.default['email-item-cc'] + ':hidden');

      var chevron = element.find('.' + _styles.default['details-chevron']);
      var chevronRightEdge = chevron.position().left + chevron.width() + offsetForTrigger;

      var difference = Math.ceil(chevronRightEdge - parentRightEdge);

      this.set('othersCount', childrenHidden.length + 1);
      var visibilityToggledCount = -1;
      if (difference > 0 && childrenVisible.length > 1) {
        var childrenOverflowWidth = 0;
        childrenVisible.toArray().reverse().some(function (child) {
          var width = Math.ceil($(child).width());
          var nextChildIsInView = childrenOverflowWidth + width - offsetForTrigger > difference;
          if (nextChildIsInView) {
            return true;
          }
          $(child).addClass(_styles.default.hide);
          childrenOverflowWidth += width;
          visibilityToggledCount++;
        });
      } else if (difference <= 0 && childrenHidden.length >= 1) {
        var inverseDiff = difference * -1;
        var _childrenOverflowWidth = 0;
        childrenHidden.toArray().some(function (child) {
          var width = Math.ceil($(child).width());
          var nextChildIsOverflowing = _childrenOverflowWidth + width > inverseDiff;
          if (nextChildIsOverflowing) {
            return true;
          }
          $(child).removeClass(_styles.default.hide);
          _childrenOverflowWidth += width;
          visibilityToggledCount--;
        });
      }
      element.find('.' + _styles.default['email-item-cc'] + ':visible:last').addClass(_styles.default['no-comma']);
      this.set('othersCount', this.get('othersCount') + visibilityToggledCount);
    },
    isLastCC: function isLastCC(length, others, index) {
      return others === 0 && index === length - 1 && index !== 0;
    },
    isOnlyRecipient: function isOnlyRecipient(length, others, index) {
      return others === length - 1 && index === 0;
    },


    // CPs
    ccRecipients: filterBy('post.recipients', 'isCC'),
    toRecipients: filterBy('post.recipients', 'isTo'),
    recipients: computed('ccRecipients', 'toRecipients', function () {
      var cc = this.get('ccRecipients');
      var to = this.get('toRecipients');

      var recipients = to.concat(cc);
      return recipients.uniqBy('identity.email');
    }),
    othersText: computed('othersCount', function () {
      if (this.get('othersCount') > 1) {
        return this.get('i18n').t('cases.more_info.others');
      }
      return this.get('i18n').t('cases.more_info.other');
    }),

    caseSubject: computed('case.subject', function () {
      return _npmHe.default.unescape(this.get('case.subject'));
    }),

    show: or('post.email', 'ccRecipients.length', 'toRecipients.length'),

    onAddCC: function onAddCC() {}
  });
});