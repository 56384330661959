define("frontend-cp/components/ko-text-editor/styles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "root": "ko-text-editor__root_1p5g6r",
    "container": "ko-text-editor__container_1p5g6r",
    "has-focus": "ko-text-editor__has-focus_1p5g6r",
    "note-container": "ko-text-editor__note-container_1p5g6r",
    "info-panel": "ko-text-editor__info-panel_1p5g6r",
    "submit-message": "ko-text-editor__submit-message_1p5g6r",
    "submit-message-keys": "ko-text-editor__submit-message-keys_1p5g6r",
    "header": "ko-text-editor__header_1p5g6r",
    "section": "ko-text-editor__section_1p5g6r",
    "group": "ko-text-editor__group_1p5g6r",
    "groupSeparator": "ko-text-editor__groupSeparator_1p5g6r",
    "groupCollapsed": "ko-text-editor__groupCollapsed_1p5g6r",
    "item": "ko-text-editor__item_1p5g6r",
    "itemWrap": "ko-text-editor__itemWrap_1p5g6r",
    "fileField": "ko-text-editor__fileField_1p5g6r",
    "icon-text": "ko-text-editor__icon-text_1p5g6r",
    "contents-container": "ko-text-editor__contents-container_1p5g6r",
    "contents": "ko-text-editor__contents_1p5g6r",
    "is-note": "ko-text-editor__is-note_1p5g6r",
    "froalaTextArea": "ko-text-editor__froalaTextArea_1p5g6r",
    "disabled": "ko-text-editor__disabled_1p5g6r",
    "copyInDropdown": "ko-text-editor__copyInDropdown_1p5g6r",
    "dropdownList": "ko-text-editor__dropdownList_1p5g6r",
    "dropdownListItem": "ko-text-editor__dropdownListItem_1p5g6r",
    "dropdown": "ko-text-editor__dropdown_1p5g6r",
    "buttonsDropdown": "ko-text-editor__buttonsDropdown_1p5g6r",
    "itemControlsDropdown": "ko-text-editor__itemControlsDropdown_1p5g6r",
    "footer": "ko-text-editor__footer_1p5g6r",
    "clip-icon": "ko-text-editor__clip-icon_1p5g6r",
    "upload-animation": "ko-text-editor__upload-animation_1p5g6r",
    "shimmer": "ko-text-editor__shimmer_1p5g6r",
    "footer-text": "ko-text-editor__footer-text_1p5g6r"
  };
});