define('frontend-cp/components/ko-timeline-2/list/item/component', ['exports', 'frontend-cp/helpers/ko-linkify', 'frontend-cp/helpers/ko-imagify', 'moment', 'ember-concurrency', 'frontend-cp/models/post', 'frontend-cp/lib/at-mentions', 'frontend-cp/components/ko-timeline-2/list/item/styles', 'frontend-cp/sanitizers/server-html-content', 'ember-sanitize/utils/sanitize'], function (exports, _koLinkify, _koImagify, _moment, _emberConcurrency, _post, _atMentions, _styles, _serverHtmlContent, _sanitize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var notEmpty = Ember.computed.notEmpty;
  var equal = Ember.computed.equal;
  var or = Ember.computed.or;
  var service = Ember.inject.service;
  var get = Ember.get;


  var PREVIOUS_JOINED_PROP_DEPENDENT_KEYS = ['{event,previousEvent}.destinationMedium', '{event,previousEvent}.original.postType', '{event,previousEvent}.creator.id', '{event,previousEvent}.createdAt', '{event,previousEvent}.original.isPinned', '{event,previousEvent}.original.note.isPinned'];

  var NEXT_JOINED_PROP_DEPENDENT_KEYS = ['{event,nextEvent}.destinationMedium', '{event,nextEvent}.original.postType', '{event,nextEvent}.creator.id', '{event,nextEvent}.createdAt', '{event,nextEvent}.original.isPinned', '{event,nextEvent}.original.note.isPinned'];

  exports.default = Component.extend({
    tagName: '',

    // Attributes
    event: null,
    posts: null,
    parent: null,
    isReplyDisabled: false,
    canReplyToPost: false,
    canResend: true,
    isCasePage: false,
    replyingTo: false,
    isOrgOrUserNote: false,
    isItemMenuOpen: false,
    onReplyWithQuote: function onReplyWithQuote() {},
    onAddCC: null,
    onResend: function onResend() {},
    onItemMenuOpen: function onItemMenuOpen() {},

    // State
    originalEmailView: false,
    originalEmailHtmlContent: null,
    showModal: false,
    showHtmlReplies: false,

    // Services
    store: service(),
    notification: service(),
    session: service(),
    i18n: service(),
    errorHandler: service(),

    // Lifecycle Hooks
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var emailInboundHtmlSetting = this.get('store').peekAll('setting').findBy('key', 'email.email_inbound_html');

      this.set('showHtmlReplies', emailInboundHtmlSetting ? emailInboundHtmlSetting.get('toBoolean') : false);
    },


    // CPs
    isNote: or('isPostTypeNote', 'isOrgOrUserNote'),
    notePinnedBy: or('event.original.pinnedBy', 'event.original.note.pinnedBy'),
    isPostTypeNote: equal('event.original.postType', 'note'),
    isUserAgentAvailable: notEmpty('event.metadata.user_agent'),
    isPageUrlAvailable: notEmpty('event.metadata.page_url'),
    isWaiting: equal('event.postStatus', _post.POST_STATUS_CLIENT_WAITING),
    isSending: equal('event.postStatus', _post.POST_STATUS_CLIENT_SENDING),
    isFailed: equal('event.postStatus', _post.POST_STATUS_CLIENT_FAILED),
    isRateLimited: equal('event.postRateLimited', true),
    userAgent: computed('event.metadata.user_agent', function () {
      return this.get('event.metadata.user_agent');
    }),
    pageUrl: computed('event.metadata.page_url', function () {
      var result = this.get('event.metadata.page_url');
      // If scheme is absent then prepend it to the url
      if (!result.match(/^[a-zA-Z]+:\/\//)) {
        result = 'http://' + result;
      }

      return result;
    }),

    pinTooltipText: computed('isNotePinned', function () {
      var i18n = this.get('i18n');
      var isPinned = this.get('isNotePinned');
      var modelName = this.get('event.original.object.name');
      var pinMode = isPinned ? 'unpin' : 'pin';

      switch (modelName) {
        case 'user':
          return i18n.t('generic.' + pinMode + '.user');
        case 'organization':
          return i18n.t('generic.' + pinMode + '.org');
        default:
          return i18n.t('generic.' + pinMode + '.case');
      }
    }),

    qaClass: computed('event', 'isNote', function () {
      // for user & org feeds
      if (this.get('isNote')) {
        return 'qa-feed_item--note';
      }

      // for case feeds
      var postType = this.get('event.sourceChannel.channelType');
      switch (postType) {
        case 'NOTE':
          return 'qa-feed_item--note';
        case 'TWITTER':
          return 'qa-feed_item--twitter-post';
        case 'HELPCENTER':
          return 'qa-feed_item--helpcenter-post';
        default:
          return 'qa-feed_item--post';
      }
    }),

    postTypeIsEmail: computed('event.sourceChannel.channelType', 'event.destinationMedium', function () {
      var postType = this.get('event.sourceChannel.channelType');
      var medium = this.get('event.destinationMedium');
      return (postType === 'MAIL' || postType === 'MAILBOX') && (!medium || medium === 'MAIL');
    }),

    isInboundEmail: computed('postTypeIsEmail', 'event.destinationMedium', function () {
      return this.get('postTypeIsEmail') && !this.get('event.destinationMedium');
    }),

    viaTranslationKey: computed('event.sourceChannel.channelType', 'event.original.postType', 'isNote', 'isMessenger', function () {
      var postType = this.get('event.sourceChannel.channelType');
      var originalPostType = this.get('event.original.postType');

      if (this.get('isMessenger')) {
        return 'messenger';
      }

      // for user & org feeds
      if (this.get('isNote')) {
        return 'note';
      }

      // for case feeds
      switch (postType) {
        case 'TWITTER':
          return originalPostType === 'twitterMessage' ? 'twitter_dm' : 'twitter';
        case 'HELPCENTER':
          return 'help_center';
        case 'CHAT':
        case 'MESSAGE':
        case 'MESSENGER':
          return 'messenger';
        case 'MAILBOX':
        case 'MAIL':
          return 'mail';
        case 'FACEBOOK':
          return 'facebook';
        default:
          return 'none';
      }
    }),

    isMessenger: computed('event.sourceChannel.channelType', 'event.destinationMedium', function () {
      return this.get('event.sourceChannel.channelType') === 'MESSENGER' || this.get('event.destinationMedium') === 'MESSENGER';
    }),

    isNotePinned: computed.or('event.original.isPinned', 'event.original.note.isPinned'),

    bodyHtml: computed.or('event.original.note.bodyHtml', 'event.original.bodyHtml'),
    bodyText: computed.or('event.original.note.bodyText', 'event.contents'),

    postItem: computed.or('event.original.note', 'event'),
    atMentionsSupported: computed('isNote', 'isOrgOrUserNote', function () {
      var _getProperties = this.getProperties('isNote', 'isOrgOrUserNote'),
          isNote = _getProperties.isNote,
          isOrgOrUserNote = _getProperties.isOrgOrUserNote;

      var isConversationNote = isNote && !isOrgOrUserNote;

      return isConversationNote;
    }),

    showTextReplies: computed.not('showHtmlReplies'),

    eventContents: computed('bodyHtml', 'bodyText', 'atMentionsSupported', 'isInboundEmail', 'showHtmlReplies', function () {
      if (this.get('showHtmlReplies') && this.get('isInboundEmail') && this.get('bodyHtml')) {
        var bodyHtml = this.get('bodyHtml');
        var content = (0, _koLinkify.stripDomainFromLinkHrefs)(bodyHtml);

        content = (0, _koImagify.imagify)([content]);

        return { content: content.string };
      } else {
        var _getProperties2 = this.getProperties('bodyHtml', 'bodyText'),
            _bodyHtml = _getProperties2.bodyHtml,
            bodyText = _getProperties2.bodyText;

        _bodyHtml = this._sanitizeContent(_bodyHtml);
        var postType = this.get('event.original.postType') || this.get('event.original.note.postType');
        var eventCreatorIsCollaboratorOrHigher = this.get('event.creator.role.isCollaboratorOrHigher');

        if (['message', 'note'].includes(postType) && _bodyHtml && eventCreatorIsCollaboratorOrHigher) {
          var _content = (0, _koLinkify.stripDomainFromLinkHrefs)(_bodyHtml);
          _content = (0, _koImagify.imagify)([_content]);
          _content.string = _content.string.split('<a ').join('<a target="_blank" ');

          if (this.get('atMentionsSupported')) {
            return this._handleContentWithMentionSupport(_content.string);
          } else {
            return this._handleContentWithoutMentionSupport(_content.string);
          }
        } else {
          bodyText = bodyText.replace(/src="data:text\/html;base64,([^"]*)"/gm, "src=''");
          var contents = (0, _koLinkify.linkify)([bodyText]);
          contents = (0, _koImagify.imagify)([contents.string]);
          contents = Ember.Handlebars.Utils.escapeExpression(contents);
          contents = contents.replace(/(\r\n|\n|\r)/gm, '<br />');
          return { content: (0, _koLinkify.stripDomainFromLinkHrefs)(contents) };
        }
      }
    }),

    _extractMentions: function _extractMentions(content) {
      return (0, _atMentions.extractMentions)(content);
    },
    _sanitizeContent: function _sanitizeContent(content) {
      return (0, _sanitize.sanitize)(content, _serverHtmlContent.default);
    },
    _replaceMentionsWithWormhole: function _replaceMentionsWithWormhole(html, mention) {
      return (0, _atMentions.replaceMention)(html, mention.id, '<div id="' + mention.id + '" class="' + _styles.default['mention-wormhole'] + '"/>');
    },
    _handleContentWithMentionSupport: function _handleContentWithMentionSupport(content) {
      var mentions = this._extractMentions(content);

      content = this._sanitizeContent(content);

      content = mentions.reduce(this._replaceMentionsWithWormhole, content);

      return { content: content, mentions: mentions };
    },
    _handleContentWithoutMentionSupport: function _handleContentWithoutMentionSupport(content) {
      content = (0, _atMentions.replaceMentionsWithPlainText)(content);

      content = this._sanitizeContent(content);

      return { content: content };
    },


    previousEvent: computed('posts.[]', function () {
      var _getProperties3 = this.getProperties('event', 'posts'),
          event = _getProperties3.event,
          posts = _getProperties3.posts;

      var index = posts.indexOf(event);
      return posts.objectAt(index - 1);
    }),

    nextEvent: computed('posts.[]', function () {
      var _getProperties4 = this.getProperties('event', 'posts'),
          event = _getProperties4.event,
          posts = _getProperties4.posts;

      var index = posts.indexOf(event);
      return posts.objectAt(index + 1);
    }),

    isJoinedWithPrevious: computed.apply(undefined, PREVIOUS_JOINED_PROP_DEPENDENT_KEYS.concat([function () {
      var _getProperties5 = this.getProperties('previousEvent', 'event'),
          previousEvent = _getProperties5.previousEvent,
          event = _getProperties5.event;

      return this.isJoined(previousEvent, event);
    }])),

    isJoinedWithNext: computed.apply(undefined, NEXT_JOINED_PROP_DEPENDENT_KEYS.concat([function () {
      var _getProperties6 = this.getProperties('nextEvent', 'event'),
          nextEvent = _getProperties6.nextEvent,
          event = _getProperties6.event;

      return this.isJoined(event, nextEvent);
    }])),

    previousIsSameSourceNote: computed.apply(undefined, PREVIOUS_JOINED_PROP_DEPENDENT_KEYS.concat([function () {
      var _getProperties7 = this.getProperties('previousEvent', 'event'),
          previousEvent = _getProperties7.previousEvent,
          event = _getProperties7.event;

      return this.isJoinedNote(previousEvent, event);
    }])),

    nextIsSameSourceNote: computed.apply(undefined, NEXT_JOINED_PROP_DEPENDENT_KEYS.concat([function () {
      var _getProperties8 = this.getProperties('nextEvent', 'event'),
          nextEvent = _getProperties8.nextEvent,
          event = _getProperties8.event;

      return this.isJoinedNote(event, nextEvent);
    }])),

    isJoined: function isJoined(previousEvent, nextEvent) {
      if (!previousEvent || !nextEvent) {
        return false;
      }

      if (Boolean(previousEvent.get('original.isPinned')) !== Boolean(nextEvent.get('original.isPinned'))) {
        return false;
      }
      if (Boolean(previousEvent.get('original.note.isPinned')) !== Boolean(nextEvent.get('original.note.isPinned'))) {
        return false;
      }

      // are they types that can be joined?

      if (!this.isValidType(previousEvent, nextEvent, ['note', 'chat', 'message'])) {
        return false;
      }

      // are they both the same type?

      if (!this.isSameType(previousEvent, nextEvent)) {
        return false;
      }

      // were they delivered by the same medium?

      if (previousEvent.get('destinationMedium') !== nextEvent.get('destinationMedium')) {
        return false;
      }

      // are they by the same person?

      var previousCreatedBy = previousEvent.get('creator.id');
      var createdBy = nextEvent.get('creator.id');

      if (previousCreatedBy !== createdBy) {
        return false;
      }

      // are they posted within 5 minutes of each other?

      var previousCreatedAt = previousEvent.get('createdAt');
      var createdAt = nextEvent.get('createdAt');
      var diff = (0, _moment.default)(createdAt).diff(previousCreatedAt, 'minutes');
      return diff <= 5;
    },
    isJoinedNote: function isJoinedNote(previousEvent, nextEvent) {
      if (!previousEvent || !nextEvent) {
        return false;
      }

      var bothEventsAreDirectlyNotes = previousEvent.get('original.postType') === 'note' && nextEvent.get('original.postType') === 'note';
      var bothEventsContainNotes = previousEvent.get('original.note.postType') === 'note' && nextEvent.get('original.note.postType') === 'note';

      var neitherEventIsNoteRelatedInTheSameWay = !bothEventsAreDirectlyNotes && !bothEventsContainNotes;
      if (neitherEventIsNoteRelatedInTheSameWay) {
        return false;
      }

      // There are 3 states possible. undefined, 'user', and 'org...'. It's undefined when it's a case note.
      var bothEventsBelongToTheSameSource = previousEvent.get('original.object.name') === nextEvent.get('original.object.name');
      if (!bothEventsBelongToTheSameSource) {
        return false;
      }

      if (Boolean(previousEvent.get('original.isPinned')) !== Boolean(nextEvent.get('original.isPinned'))) {
        return false;
      }

      if (Boolean(previousEvent.get('original.note.isPinned')) !== Boolean(nextEvent.get('original.note.isPinned'))) {
        return false;
      }

      return true;
    },
    isValidType: function isValidType(previousEvent, nextEvent, types) {
      var previousEventType = previousEvent.get('original.postType') || previousEvent.get('original.note.postType');
      var nextEventType = nextEvent.get('original.postType') || nextEvent.get('original.note.postType');

      if (previousEvent.get('isViaEmail') || nextEvent.get('isViaEmail')) {
        //the only events with a type of message that are valid to join are messenger messages
        return false;
      }

      return types.includes(previousEventType) && types.includes(nextEventType);
    },
    isSameType: function isSameType(previousEvent, nextEvent) {
      //User and org notes can only be differentiated by checking the activity
      var previousType = this.supportMessenger1and2(previousEvent.get('original.postType')) || previousEvent.get('original.activity');
      var type = this.supportMessenger1and2(nextEvent.get('original.postType')) || nextEvent.get('original.activity');
      return previousType === type;
    },
    supportMessenger1and2: function supportMessenger1and2(currentEvent) {
      //Messenger 2
      if (currentEvent === 'message') {
        return 'chat';
      }

      //Messenger 1
      return currentEvent;
    },


    toggleNotePin: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(entity, activity) {
      var note, entityId, pinNote, user, errMessage, entityName;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              note = void 0, entityId = void 0, pinNote = void 0, user = void 0, errMessage = void 0;
              entityName = this.get('event.original.object.name');
              _context.t0 = entityName;
              _context.next = _context.t0 === 'organization' ? 5 : _context.t0 === 'user' ? 8 : 11;
              break;

            case 5:
              entityId = entity.get('requester.organization.id');
              entityName = 'organizations';
              return _context.abrupt('break', 13);

            case 8:
              entityId = entity.get('requester.id');
              entityName = 'users';
              return _context.abrupt('break', 13);

            case 11:
              entityId = entity.get('id');
              entityName = 'cases';

            case 13:

              if (activity.get('postType') === 'note') {
                note = activity.get('content');
              } else {
                note = activity.get('note.content');
              }

              this.get('parent.viewNotes').addObject(note);
              pinNote = !note.get('isPinned');

              _context.prev = 16;

              if (pinNote) {
                user = this.get('session.user');
              }
              note.setProperties({ isPinned: pinNote, pinnedBy: user });
              _context.next = 21;
              return note.save({ adapterOptions: { entityName: entityName, entityId: entityId } });

            case 21:
              _context.next = 28;
              break;

            case 23:
              _context.prev = 23;
              _context.t1 = _context['catch'](16);

              if (pinNote) {
                errMessage = 'generic.pin_failed';
              } else {
                errMessage = 'generic.unpin_failed';
              }

              note.rollbackAttributes();

              this.get('notification').error(this.get('i18n').t(errMessage));

            case 28:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this, [[16, 23]]);
    })).drop(),

    fetchOriginalEmailContents: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      var _this = this;

      var _getProperties9, store, event;

      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _getProperties9 = this.getProperties('store', 'event'), store = _getProperties9.store, event = _getProperties9.event;
              _context2.next = 3;
              return this.get('errorHandler').disableWhile(function () {
                return store.findRecord('email-original', get(event, 'id')).then(function (email) {
                  var content = get(email, 'htmlContent');

                  if (content) {
                    _this.set('originalEmailHtmlContent', content);
                    _this.set('originalEmailView', true);
                  }
                }, function (_ref) {
                  var errors = _ref.errors;

                  if (errors && errors[0] && errors[0].code === 'RESOURCE_NOT_FOUND') {
                    _this.set('errorType', 404);
                  } else {
                    _this.set('errorType', 500);
                  }
                });
              });

            case 3:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    actions: {
      onMenuOpen: function onMenuOpen(isOpen) {
        this.set('isThisMenuOpen', isOpen);
        this.sendAction('onItemMenuOpen', isOpen);
      }
    }
  });
});