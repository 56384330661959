define('frontend-cp/components/ko-case-content/component', ['exports', 'ember-service/inject', 'ember-data/adapters/errors', 'ember-concurrency', 'npm:lodash', 'npm:he', 'frontend-cp/lib/html-to-text', 'frontend-cp/utils/tweet-length', 'ember-keyboard-shortcuts/mixins/component', 'frontend-cp/utils/format-validations', 'frontend-cp/utils/presence', 'frontend-cp/lib/keycodes', 'frontend-cp/lib/copy-to-clipboard', 'ember-diff-attrs', 'frontend-cp/components/ko-case-content/styles', 'moment', 'frontend-cp/lib/facade/channel', 'ember-sanitize/utils/sanitize', 'frontend-cp/lib/at-mentions', 'frontend-cp/components/ko-text-editor/component', 'frontend-cp/utils/bugsnag', 'frontend-cp/utils/is-internal-tag'], function (exports, _inject, _errors, _emberConcurrency, _npmLodash, _npmHe, _htmlToText, _tweetLength, _component, _formatValidations, _presence, _keycodes, _copyToClipboard, _emberDiffAttrs, _styles, _moment, _channel, _sanitize, _atMentions, _component2, _bugsnag, _isInternalTag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var next = Ember.run.next;
  var throttle = Ember.run.throttle;
  var EmberObject = Ember.Object;
  var isBlank = Ember.isBlank;
  var isEmpty = Ember.isEmpty;
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var readOnly = Ember.computed.readOnly;
  var alias = Ember.computed.alias;
  var filterBy = Ember.computed.filterBy;
  var bool = Ember.computed.bool;
  var or = Ember.computed.or;
  var assign = Ember.assign;
  var capitalize = Ember.String.capitalize;
  var scheduleOnce = Ember.run.scheduleOnce;
  var debounce = Ember.run.debounce;
  var observer = Ember.observer;
  var getOwner = Ember.getOwner;


  var KRE_ACTIVITY_DEBOUNCE = 1000 * 10;
  var CUSTOMER_ROLE_ID = 4;
  var KRE_END_TYPING_DEBOUNCE = Ember.testing ? 0 : 5000;
  var FOCUS_DEBOUNCE_TIME = 400;

  exports.default = Component.extend(_component.default, {
    launchDarkly: (0, _inject.default)(),

    // Attributes
    timeline: null,
    case: null,
    postId: null,
    noteId: null,
    filter: '',
    caseFields: null,
    caseForms: null,
    priorities: [],
    statuses: [],
    types: [],
    onQueryParamsUpdate: null,
    onCaseCreate: null,
    tabId: null,
    agents: null,
    teams: null,
    tabsComponent: null,
    hasUnreadMessages: false,
    tabsModel: null,
    instantEntityTerm: '',
    instantEntityResults: null,
    setOrganizationMode: false,
    setRequesterMode: false,
    unsetOrgInProgress: false,
    removedOrg: null,
    isBeingCreated: false,
    isBreached: false,
    isRequesterOnline: null,
    isImageUploading: false,

    // State
    state: null,
    isTyping: false,

    // HTML
    classNames: ['ko-case-content'],
    localClassNames: ['root'],

    // Services
    store: service(),
    socket: service(),
    i18n: service(),
    plan: service(),
    permissionService: service('permissions'),
    notification: service('notification'),
    customFieldsList: service('custom-fields/list'),
    apiAdapter: service('api-adapter'),
    sessionService: service('session'),
    confirmation: service(),
    mergeConversation: service(),
    uploadService: service('fileUpload'),
    serverClock: service(),
    routing: service('-routing'),
    notificationCenter: service(),

    keyboardShortcuts: {
      'ctrl+alt+r': {
        action: 'openReply',
        global: false,
        preventDefault: true
      },
      'ctrl+alt+n': {
        action: 'openNote',
        global: false,
        preventDefault: true
      },
      r: {
        action: 'openReply',
        global: false
      },
      n: {
        action: 'openNote',
        global: false
      },
      s: {
        action: 'editSubject',
        global: false
      },
      'ctrl+alt+s': {
        action: 'editSubject',
        global: false,
        preventDefault: true
      },
      'mod+enter': {
        action: 'submit',
        global: true,
        preventDefault: true
      }
    },

    // Lifecycle hooks
    didReceiveAttrs: (0, _emberDiffAttrs.default)('case', 'tabId', function (changedAttrs) {
      var _this = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);

      if (!changedAttrs || changedAttrs.case) {
        this.get('reloadRequester').perform();

        this.get('suggestPeople').cancelAll();
        this.get('typing').cancelAll();
        this.get('serverClock').restartRunningTick();

        if (!this.get('case.isNew')) {
          this.get('fetchNotes').perform();
          this.get('getBrands').perform();
        }

        this._markNotificationAsRead();
        this.get('state.initChannels').perform();

        next(function () {
          _this.setTabsModelRequester();
          _this.setChannelWhenRequesterPresent();
          _this.focusSubjectOrReply();
        });
      }
    }),

    willDestroyElement: function willDestroyElement() {
      this.updatePresenceMeta(this.get('case'), { is_foreground: false });
    },


    requesterChanged: observer('editedCase.requester', function () {
      this.setChannelWhenRequesterPresent();
    }),

    // State attributes
    postContent: readOnly('state.postContent'),
    attachedPostFiles: readOnly('state.attachedPostFiles'),
    channel: readOnly('state.channel'),
    posts: readOnly('state.posts'),
    errorMap: readOnly('state.errorMap'),
    singlePropertyUpdating: bool('state.setBrand.isRunning'),
    isSaving: computed('state.{arePropertiesEdited,isSaving}', 'hasUpdateSendingOperations', function () {
      var isStateSaving = this.get('state.isSaving');
      var propertiesAreEdited = this.get('state.arePropertiesEdited');
      var hasSendingOperations = this.get('timeline.sendingOperations.length');
      var hasUpdateSendingOperations = this.get('hasUpdateSendingOperations');

      return propertiesAreEdited && hasSendingOperations || hasUpdateSendingOperations || isStateSaving;
    }),

    hasUpdateSendingOperations: computed('timeline.sendingOperations.@each.code', function () {
      var sendingOperations = this.get('timeline.sendingOperations') || [];
      return sendingOperations.any(function (op) {
        return op.code !== 'reply';
      });
    }),

    replyOptions: readOnly('state.replyOptions'),
    editedTags: readOnly('state.editedTags'),
    propertiesChangeViaKRE: readOnly('state.propertiesChangeViaKRE'),
    editedCase: readOnly('state.editedCase'),
    viewingUsers: readOnly('state.viewingUsers'),
    requester: readOnly('state.editedCase.requester'),
    uploadingFiles: readOnly('uploadService.uploadFile.isRunning'),
    selectedNoteDestination: readOnly('state.noteDestination'),
    atMentionsSupported: readOnly('state.atMentionsSupported'),

    noteDestinations: computed('hasOrg', function () {
      var i18n = this.get('i18n');
      var options = [{
        id: 'case',
        text: i18n.t('cases.conversation'),
        description: i18n.t('generic.texteditor.case_notes_reminder')
      }, {
        id: 'user',
        text: i18n.t('users.title'),
        description: i18n.t('generic.texteditor.user_notes_reminder')
      }];

      if (this.get('hasOrg')) {
        options.addObject({
          id: 'org',
          text: i18n.t('organization.title'),
          description: i18n.t('generic.texteditor.org_notes_reminder')
        });
      }

      return options;
    }),

    noteDestination: or('selectedNoteDestination', 'noteDestinations.firstObject'),

    updateLog: computed('state.updateLog.[]', function () {
      var userId = this.get('sessionService.user.id');

      return this.get('state.updateLog').filter(function (update) {
        return update.get('user.id') !== userId;
      });
    }),

    isSlaCompleted: computed('case.slaMetrics', 'isSaving', function () {
      return this.get('relavantSla.stage') === 'COMPLETED';
    }),

    slaBarStatus: computed('isSlaCompleted', 'isSlaPaused', 'isBreached', 'isSaving', 'case.slaMetrics', function () {
      if (this.get('case.slaMetrics').isAny('stage', 'PAUSED')) {
        return 'paused';
      }
      if (this.get('isSlaCompleted') && this.get('isBreached')) {
        return 'completedAndBreached';
      }
      if (this.get('isSlaCompleted')) {
        return 'completed';
      }
      if (this.get('isBreached')) {
        return 'bad';
      }
      return 'active';
    }),

    slaIcon: computed('slaBarStatus', function () {
      switch (this.get('slaBarStatus')) {
        case 'paused':
          return 'pause';
        case 'completed':
          return 'tick';
        case 'completedAndBreached':
          return 'tick';
        default:
          return 'clock';
      }
    }),

    metricString: computed('isBreached', function () {
      if (this.get('isBreached')) {
        return 'cases.sla.phrase.bad';
      }

      return 'cases.sla.in';
    }),

    sortedMetrics: computed('case.slaMetrics', function () {
      var metrics = this.get('case.slaMetrics');

      var completed = metrics.filter(function (metric) {
        return metric.get('isCompleted') && (0, _moment.default)(metric.get('dueAt')).isAfter((0, _moment.default)(metric.get('completedAt')));
      }).sort(function (metric) {
        return (0, _moment.default)(metric.get('completedAt')).diff((0, _moment.default)(metric.get('dueAt')));
      });

      var completedAndBreached = metrics.filter(function (metric) {
        return metric.get('isCompleted') && (0, _moment.default)(metric.get('completedAt')).isAfter((0, _moment.default)(metric.get('dueAt')));
      }).sort(function (metric) {
        return (0, _moment.default)(metric.get('completedAt')).diff((0, _moment.default)(metric.get('dueAt')));
      });

      var incomplete = metrics.rejectBy('isCompleted').sortBy('dueAt');
      return incomplete.concat(completedAndBreached.concat(completed));
    }),

    relavantSla: computed('case.slaMetrics', function () {
      return this.get('sortedMetrics.firstObject');
    }),

    creatorIdentityTwitterHandle: computed('editedCase.identity', function () {
      var identity = this.get('editedCase.identity');

      if (!identity) {
        return '';
      }

      return identity.constructor.modelName !== 'identity-twitter' ? '' : identity.get('screenName');
    }),

    fetchNewerPostsAfterReply: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var filter;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              if (!(this.isDestroyed || this.isDestroying)) {
                _context.next = 2;
                break;
              }

              return _context.abrupt('return', []);

            case 2:
              filter = this.get('filter');


              this._defaultToReplyingToTheLastMessageFromTheCustomer();
              _context.next = 6;
              return this.get('timeline.fetchNewerAfterReply').perform(filter);

            case 6:
              return _context.abrupt('return', _context.sent);

            case 7:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    typingString: computed('channelPresence', 'sessionService.user.id', function () {
      var presence = this.get('channelPresence');
      if (!presence) {
        return null;
      }

      var metas = (0, _presence.list)(presence, function (id, _ref) {
        var metas = _ref.metas;

        return assign({}, metas[0], {
          is_typing: metas.any(function (m) {
            return m.is_typing;
          })
        });
      });

      var userID = this.get('sessionService.user.id');

      var users = metas.filter(function (m) {
        return m.user;
      }).filter(function (m) {
        return String(m.user.id) !== userID;
      }).filter(function (m) {
        return m.is_typing;
      }).map(function (m) {
        return m.user.full_name;
      });

      var numUsers = users.length;

      if (!numUsers) {
        return null;
      }

      var lastUser = numUsers > 1 ? users.pop() : null;

      return this.get('i18n').t('cases.realtimeTyping', {
        total: numUsers,
        lastUser: lastUser,
        sentence: users.join(', ')
      });
    }),

    triggerActivity: function triggerActivity() {
      this.updatePresenceMeta(this.get('case'), { is_updating: this.get('state.isEdited') });
      this.legacyTriggerActivity();
    },


    // TODO - remove this once Android/iOS clients stop relying on it
    legacyTriggerActivity: function legacyTriggerActivity() {
      var id = this.get('sessionService.user.id');
      var data = {
        id: id,
        isUpdating: this.get('state.isEdited')
      };

      var channelName = this.get('case.realtimeChannel');
      if (this.get('socket').hasJoinedChannel(channelName)) {
        this.get('socket').push(channelName, 'client-activity', data);
      }
    },
    mouseMove: function mouseMove(event) {
      throttle(this, this.triggerActivity, KRE_ACTIVITY_DEBOUNCE);
    },


    // CPs
    hasUser: computed.bool('editedCase.requester'),
    hasOrg: computed.or('editedCase.requester.organization.name', 'removedOrg'),
    hasSubject: computed.notEmpty('editedCase.subject'),
    caseSubject: computed('editedCase.subject', function () {
      return _npmHe.default.unescape(this.get('editedCase.subject'));
    }),

    showTabs: computed('hasUser', 'setRequesterMode', 'setOrganizationMode', 'tabsModel.organization.name', function () {
      return !this.get('setRequesterMode') && this.get('hasUser') && (!this.get('setOrganizationMode') || this.get('tabsModel.organization.name'));
    }),

    showSendButton: computed('addExternalNote.isRunning', 'hasReplyContent', 'hasUpdateSendingOperations', 'canUpdateAndResend', 'isImageUploading', function () {
      var addExternalNoteisRunning = this.get('addExternalNote.isRunning');
      var hasReplyContent = this.get('hasReplyContent');
      var hasUpdateSendingOperations = this.get('hasUpdateSendingOperations');
      var canUpdateAndResend = this.get('canUpdateAndResend');
      var isImageUploading = this.get('isImageUploading');

      return (addExternalNoteisRunning || hasReplyContent || hasUpdateSendingOperations) && !canUpdateAndResend && !isImageUploading;
    }),

    completedStatus: computed('statuses.[]', function () {
      return this.get('statuses').findBy('statusType', 'COMPLETED');
    }),

    canTrashCase: computed('isBeingCreated', function () {
      var permission = this.get('permissionService').has('cases.trash');

      return !this.get('isBeingCreated') && permission;
    }),

    canMergeCase: computed(function () {
      return this.get('permissionService').has('cases.merge');
    }),

    createMenuItem: function createMenuItem(menu, value, action, args) {
      var children = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];

      return {
        id: menu ? menu.length + 1 : null,
        value: value,
        object: {
          action: action, args: args
        },
        children: children.length ? children : null
      };
    },


    navigationMenuItems: computed('isCaseClosed', 'isBeingCreated', 'areFieldsDisabled', 'canMergeCase', 'isCaseTrashed', 'canTrashCase', 'filter', 'caseBrandName', 'enabledBrands', 'case.brand', function () {
      var _this2 = this;

      var i18n = this.get('i18n');

      var _getProperties = this.getProperties('isCaseClosed', 'isBeingCreated', 'areFieldsDisabled', 'canMergeCase', 'isCaseTrashed', 'canTrashCase', 'filter', 'caseBrandName', 'enabledBrands'),
          isCaseClosed = _getProperties.isCaseClosed,
          isBeingCreated = _getProperties.isBeingCreated,
          areFieldsDisabled = _getProperties.areFieldsDisabled,
          canMergeCase = _getProperties.canMergeCase,
          isCaseTrashed = _getProperties.isCaseTrashed,
          canTrashCase = _getProperties.canTrashCase,
          filter = _getProperties.filter,
          caseBrandName = _getProperties.caseBrandName,
          enabledBrands = _getProperties.enabledBrands;

      var menu = [];

      if (!isCaseClosed) {
        if (!areFieldsDisabled) {
          menu.push(this.createMenuItem(menu, i18n.t('cases.change_requester'), this, ['setRequesterModeOn']));
        }
        if (canMergeCase && !isCaseTrashed) {
          menu.push(this.createMenuItem(menu, i18n.t('cases.merge_conversation.title'), this.get('state.mergeCase').perform));
        }
      }
      if (!isBeingCreated) {
        if (canTrashCase && isCaseTrashed) {
          menu.push(this.createMenuItem(menu, i18n.t('cases.restore'), this.get('state.restoreCase').perform));
        }
        if (filter === 'messages') {
          menu.push(this.createMenuItem(menu, i18n.t('cases.filter_options.posts_activities'), this, ['setFilter', 'all']));
        } else {
          menu.push(this.createMenuItem(menu, i18n.t('cases.filter_options.posts'), this, ['setFilter', 'messages']));
        }
        if (enabledBrands.length > 1 && this.get('isAgentOrHigher')) {
          var sortedBrands = enabledBrands.sortBy('name');
          menu.push(this.createMenuItem(null, i18n.t('generic.change_brand') + '<span>&nbsp;- ' + (0, _sanitize.sanitize)(caseBrandName) + '</span>', null, null, sortedBrands.map(function (brand) {
            return _this2.createMenuItem(menu, {
              text: (0, _sanitize.sanitize)(brand.get('name')),
              isSelected: brand.get('id') === _this2.get('case.brand.id'),
              isDefault: brand.get('isDefault')
            }, _this2.get('setBrand').perform, [brand]);
          })));
        }
      }

      return menu;
    }),

    twitterReplyOptions: computed('creatorIdentityTwitterHandle', function () {
      var handle = this.get('creatorIdentityTwitterHandle');
      var i18n = this.get('i18n');

      return [{
        id: 'tweet.public',
        type: 'REPLY',
        text: i18n.t('cases.tweet.public')
      }, {
        id: 'tweet.public-invite-dm',
        type: 'REPLY_WITH_PROMPT',
        text: i18n.t('cases.tweet.public-invite-dm'),
        description: i18n.t('cases.tweet.public-invite-dm-description', { handle: handle })
      }, {
        id: 'tweet.dm',
        type: 'DM',
        text: i18n.t('cases.tweet.dm')
      }];
    }),

    twitterReplyValue: computed('replyOptions.type', function () {
      var optionState = this.get('replyOptions');
      var twitterReplyOptions = this.get('twitterReplyOptions');

      var option = twitterReplyOptions.find(function (replyOption) {
        return replyOption.type === optionState.get('type');
      });

      if (!option) {
        return twitterReplyOptions.get('firstObject');
      }

      return option;
    }),

    macros: computed(function () {
      return this.get('store').peekAll('macro');
    }),

    enabledCaseForms: computed('caseForms.[]', function () {
      return this.get('caseForms').filterBy('isEnabled').filterBy('isDeleted', false);
    }),

    isRichFormattingAvailable: computed('channel', 'channel.isChannelTypeMailbox', function () {
      return this.get('channel.channelType') === 'NOTE' || this.get('channel.isChannelTypeMailbox');
    }),

    tags: computed('case.tags.@each.name', function () {
      return this.get('case.tags').map(function (tag) {
        return tag.get('name');
      });
    }),

    publicReplyChannels: computed('case.replyChannels', function () {
      return this.get('case.replyChannels').filter(function (channel) {
        return channel.get('channelType') !== 'NOTE';
      });
    }),

    hasRequesterBeenSeenRecently: computed('editedCase.requester.lastSeenAt', 'isRequesterOnline', 'serverClock.date', function () {
      if (this.get('isRequesterOnline')) {
        return true;
      }

      var lastSeenAt = this.get('editedCase.requester.lastSeenAt');
      var serverTime = this.get('serverClock.date');
      var aboutFifteenMinutesAgo = serverTime.clone().subtract(18, 'minutes'); // 15 mins plus a small buffer to account for server caching

      return _moment.default.utc(lastSeenAt).isAfter(aboutFifteenMinutesAgo);
    }),

    lastMessageSentViaMessenger: computed('timeline.lastMessage.destinationMedium', 'timeline.lastMessage.sourceChannel.channelType', function () {
      var lastMessage = this.get('timeline.lastMessage');
      if (!lastMessage) {
        return false;
      }

      return lastMessage.get('destinationMedium') === 'MESSENGER' || lastMessage.get('sourceChannel.channelType') === 'MESSENGER';
    }),

    wrappedPublicReplyChannels: computed('publicReplyChannels', 'hasRequesterBeenSeenRecently', 'lastMessageSentViaMessenger', function () {
      var channels = this.get('publicReplyChannels');
      var isOnline = this.get('hasRequesterBeenSeenRecently') && this.get('lastMessageSentViaMessenger');

      return channels.map(function (channel) {
        return _channel.default.create({ channel: channel, isOnline: isOnline });
      });
    }),

    wrappedSelectedChannel: computed('state.publicChannel', 'wrappedPublicReplyChannels', function () {
      var channel = this.get('state.publicChannel');

      if (!channel) {
        return;
      }

      var wrappedChannels = this.get('wrappedPublicReplyChannels');
      return wrappedChannels.findBy('id', channel.get('id'));
    }),

    editorClass: computed('wrappedSelectedChannel.shouldDeliverViaMessenger', 'state.isNote', 'atMentionsSupported', function () {
      var channel = this.get('wrappedSelectedChannel');
      var isNote = this.get('state.isNote');

      if (this.get('atMentionsSupported')) {
        return 'at-mentions-supported';
      }

      if (!channel || isNote) {
        return;
      }

      if (channel.get('shouldDeliverViaMessenger')) {
        return 'channel-type--messenger';
      }

      return 'channel-type--' + channel.get('normalizedType');
    }),

    showAllCustomFields: computed('launchDarkly.release-sidebar-compaction', 'case.isNew', function () {
      var launchDarkly = this.get('launchDarkly');

      return !launchDarkly.get('release-sidebar-compaction') || this.get('case.isNew');
    }),

    caseOrFormFields: computed('caseFields', 'editedCase.form', 'showAllCustomFields', function () {
      var caseFields = this.get('caseFields');
      var form = this.get('editedCase.form');

      var fields = form ? form.get('fields') : caseFields.sortBy('sortOrder');
      if (!this.get('showAllCustomFields')) {
        var customFieldValues = this.get('state.customFields.idToEditedValueHash');
        fields = fields.filter(function (field) {
          return field.get('isSystem') || field.get('isRequiredForAgents') || !isEmpty(customFieldValues[field.get('id')]);
        });
      }

      return fields;
    }),

    emptyFieldCount: computed('launchDarkly.release-sidebar-compaction', 'caseOrFormFields', function () {
      var launchDarkly = this.get('launchDarkly');

      if (!launchDarkly.get('release-sidebar-compaction')) return 0;

      var caseFields = this.get('caseFields');
      var form = this.get('editedCase.form');
      var fields = form ? form.get('fields') : caseFields.sortBy('sortOrder');
      var customFieldValues = this.get('state.customFields.idToEditedValueHash');
      fields = fields.filter(function (field) {
        return !field.get('isSystem') && !field.get('isRequiredForAgents') && isEmpty(customFieldValues[field.get('id')]) && field.get('isEnabled');
      });

      return fields.length;
    }),

    showFormBottomArrow: computed('launchDarkly.release-sidebar-compaction', 'caseOrFormFields', function () {
      var launchDarkly = this.get('launchDarkly');

      if (!launchDarkly.get('release-sidebar-compaction')) return true;

      var caseFields = this.get('caseFields');
      var form = this.get('editedCase.form');
      var fields = form ? form.get('fields') : caseFields.sortBy('sortOrder');
      var customFields = fields.filter(function (field) {
        return !field.get('isSystem') && !field.get('isRequiredForAgents') && field.get('isEnabled');
      });

      return this.get('showAllCustomFields') || customFields.length !== this.get('emptyFieldCount');
    }),

    statusField: computed('caseOrFormFields.[]', function () {
      return this.get('caseOrFormFields').findBy('fieldType', 'STATUS');
    }),

    typeField: computed('caseOrFormFields.[]', function () {
      return this.get('caseOrFormFields').findBy('fieldType', 'TYPE');
    }),

    priorityField: computed('caseOrFormFields.[]', function () {
      return this.get('caseOrFormFields').findBy('fieldType', 'PRIORITY');
    }),

    assigneeField: computed('caseOrFormFields.[]', function () {
      return this.get('caseOrFormFields').findBy('fieldType', 'ASSIGNEE');
    }),

    caseBrandName: alias('case.brand.name'),

    hasBrand: computed('caseBrand.companyName', function () {
      return Boolean(this.get('caseBrand.companyName'));
    }),

    brands: readOnly('getBrands.lastSuccessful.value'),

    enabledBrands: filterBy('getBrands.lastSuccessful.value', 'isEnabled'),

    hasChannel: computed('case.sourceChannel.channelType', function () {
      return !!this.get('case.sourceChannel.channelType');
    }),

    isCaseClosed: computed.equal('case.status.statusType', 'CLOSED'),

    isCaseCompleted: computed.equal('case.status.statusType', 'COMPLETED'),

    isCaseTrashed: computed.equal('case.state', 'TRASH'),

    isPristine: computed.not('state.isEdited'),
    isReplyBlank: computed.not('hasReplyContent'),
    hasReplyContent: computed.reads('state.isContentEdited'),

    isTwitterDm: computed('twitterReplyValue', function () {
      return ['tweet.public', 'tweet.public-invite-dm'].indexOf(this.get('twitterReplyValue.id')) === -1;
    }),

    hasTextLimit: computed('channel.channelType', 'isTwitterDm', function () {
      return this.get('channel.channelType') === 'TWITTER' && !this.get('isTwitterDm');
    }),

    maxCharaterCountReached: computed('hasTextLimit', 'replyCharactersCount', function () {
      return this.get('hasTextLimit') && this.get('replyCharactersCount') < 0;
    }),

    isInvalid: computed.readOnly('maxCharaterCountReached'),

    isCollaborator: computed.not('sessionService.user.role.isAgentOrHigher'),
    isAgentOrHigher: computed.readOnly('sessionService.user.role.isAgentOrHigher'),

    areFieldsDisabled: computed('canUpdateAndResend', 'isSaving', 'isCaseTrashed', 'isCaseClosed', 'isCollaborator', 'singlePropertyUpdating', function () {
      if (this.get('canUpdateAndResend')) {
        return false;
      }

      return this.get('isSaving') || this.get('isCaseTrashed') || this.get('singlePropertyUpdating') || this.get('isCaseClosed') || this.get('isCollaborator');
    }),

    canUpdateAndResend: computed.and('hasErrors', 'hasQueue'),

    hasErrors: computed('errorMap', function () {
      var errorMap = this.get('errorMap');

      if (!errorMap) {
        return false;
      }

      return Object.keys(errorMap).some(function (key) {
        return errorMap.get(key);
      });
    }),
    hasQueue: computed.notEmpty('timeline.sendingOperations'),

    noUser: computed.not('hasUser'),

    isReplyDisabled: computed.or('isSaving', 'isCaseTrashed', 'isCaseClosed', 'isCollaborator'),

    submitDisabled: computed.or('isSaving', 'isPristine', 'isCaseTrashed', 'isCaseClosed', 'isInvalid', 'hasNoRequester', 'state.reloadCase.isRunning', 'uploadingFiles'),

    hasNoRequester: computed.not('editedCase.requester'),

    replyCharactersCount: computed('postContent', 'attachedPostFiles.@each.error', 'channel.characterLimit', function () {
      var characterLimit = this.get('channel.characterLimit');
      var files = this.get('attachedPostFiles').filter(function (file) {
        return file.error !== 'TOO_LARGE';
      });
      return characterLimit - (0, _tweetLength.default)(this.get('postContent'), files.length);
    }),

    planHasAgentCollisions: computed(function () {
      return this.get('plan').has('agent_collision');
    }),

    composeReplyDisabled: computed.or('hasUpdateSendingOperations', 'state.update.isRunning', 'canUpdateAndResend'),

    submitReplyDisabled: computed.or('composeReplyDisabled', 'uploadingFiles', 'isReplyBlank', 'singlePropertyUpdating', 'addExternalNote.isRunning', 'unsetOrgInProgress'),

    isShowingControls: computed('channel.channelType', function () {
      return ['MAIL', 'NOTE'].includes(this.get('channel.channelType')) || !this.get('channel.channelType');
    }),

    supportsAttachments: computed('channel.channelType', function () {
      return this.get('channel') && this.get('channel.channelType') !== 'FACEBOOK';
    }),

    dropzoneError: computed('supportsAttachments', function () {
      if (!this.get('channel')) {
        return this.get('i18n').t('generic.uploads.no_channel');
      }
      if (this.get('channel.channelType') === 'FACEBOOK') {
        return this.get('i18n').t('generic.uploads.facebook_not_allowed');
      }
    }),

    filteredStatuses: computed('case.id', 'statuses.[]', 'editedCase.status', function () {
      var caseIsPersisted = !this.get('isBeingCreated');
      var currentStatus = this.get('editedCase.status');

      return this.get('statuses').filter(function (status) {

        // statuses can only be NEW if they've never been saved
        if (status.get('statusType') === 'NEW') {
          return !caseIsPersisted || currentStatus === status;
        }

        return status.get('statusType') !== 'CLOSED' && !status.get('isDeleted');
      });
    }),

    orderedStatuses: computed('filteredStatuses.@each.sortOrder', function () {
      return this.get('filteredStatuses').sortBy('sortOrder');
    }),

    orderedStatusesExcludingCurrent: computed('orderedStatuses.[]', 'editedCase.status', function () {
      var currentStatus = this.get('editedCase.status');
      var orderedStatuses = this.get('orderedStatuses');

      return orderedStatuses.filter(function (caseStatus) {
        if (caseStatus.get('label') !== currentStatus.get('label')) {
          return caseStatus;
        }
      });
    }),

    isLoading: computed('state.initChannels.{last,isRunning}', function () {
      var task = this.get('state.initChannels');

      return !task.get('last') || task.get('isRunning');
    }),

    messengerHelpText: computed('wrappedSelectedChannel.shouldDeliverViaMessenger', 'editedCase.requester', 'state.isNote', function () {
      var channel = this.get('wrappedSelectedChannel');
      var requester = this.get('editedCase.requester');
      var inMessengerMode = channel && channel.get('shouldDeliverViaMessenger') && !this.get('state.isNote');

      if (requester && inMessengerMode) {
        return this.get('i18n').t('cases.messenger_mode', { email: channel.get('handle'), name: requester.get('fullName') });
      }
    }),

    visibleTags: computed('editedTags.@each.name', function () {
      return this.get('editedTags').filter(function (tag) {
        return !(0, _isInternalTag.default)(tag);
      });
    }),

    // Methods
    getBrands: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2() {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return this.get('store').findAll('brand');

            case 2:
              return _context2.abrupt('return', _context2.sent);

            case 3:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })),

    suggestTags: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3(searchTerm) {
      var addNewMessage, data, exactMatch;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.next = 2;
              return (0, _emberConcurrency.timeout)(300);

            case 2:
              addNewMessage = this.get('i18n').t('generic.addtagname', { tag: searchTerm });
              _context3.next = 5;
              return this.get('store').query('tag', { name: searchTerm });

            case 5:
              data = _context3.sent;
              exactMatch = !!data.toArray().findBy('name', searchTerm) || !!this.get('editedTags').findBy('name', searchTerm);
              return _context3.abrupt('return', _npmLodash.default.difference(data.mapBy('name'), this.get('editedTags').mapBy('name')).map(function (name) {
                return { name: name };
              }).concat(exactMatch ? [] : [{ name: addNewMessage, actualName: searchTerm }]));

            case 8:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })).restartable(),

    findAndTriggerFroalaFocusAction: function findAndTriggerFroalaFocusAction() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.$(_component2.EDITOR_SELECTOR).froalaEditor('events.focus');
    },
    focusFroalaEditor: function focusFroalaEditor() {
      scheduleOnce('afterRender', this, 'findAndTriggerFroalaFocusAction');
    },


    // Tasks
    openNote: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4() {
      var launchDarkly, state;
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              launchDarkly = this.get('launchDarkly');
              state = this.get('state');


              state.setNote();

              if (launchDarkly.get('release-reply-all-by-default')) {
                state.removeInReplyTo();
              }

              this.focusFroalaEditor();

            case 5:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this);
    })).drop(),

    setChannel: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee5(newChannel, force, event) {
      var state, currentChannelType, newChannelType, content, sanitized;
      return regeneratorRuntime.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              state = this.get('state');
              currentChannelType = this.get('channel.channelType');
              newChannelType = newChannel.get('channelType');
              content = state.get('postContent');

              if (!((0, _htmlToText.HTMLContainsFormatting)(content) && currentChannelType !== newChannelType && ['TWITTER', 'FACEBOOK'].indexOf(newChannelType) > -1)) {
                _context5.next = 9;
                break;
              }

              _context5.next = 7;
              return this.get('confirmation').confirm({
                intlConfirmationHeader: 'cases.channel.confirm.sanitize_text_header',
                intlConfirmationBody: 'cases.channel.confirm.sanitize_text'
              });

            case 7:
              sanitized = (0, _htmlToText.stripFormattingFromHTML)(content);

              state.setPostContent(sanitized);

            case 9:
              state.setChannel(newChannel);

              if (force) {
                if (event) {
                  event.preventDefault();
                }
                this.focusFroalaEditor();
              } else {
                this.focusSubjectOrReply();
              }

              if (currentChannelType !== 'MAIL') {
                this._defaultToReplyingToTheLastMessageFromTheCustomer();
              }

            case 12:
            case 'end':
              return _context5.stop();
          }
        }
      }, _callee5, this);
    })).drop(),

    replyToPost: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee6(post) {
      var channel, state, conversationRequester;
      return regeneratorRuntime.wrap(function _callee6$(_context6) {
        while (1) {
          switch (_context6.prev = _context6.next) {
            case 0:
              channel = post.get('sourceChannel');
              state = this.get('state');
              conversationRequester = this.get('case.requester');

              if (!(channel && this.get('publicReplyChannels').findBy('id', channel.get('id')))) {
                _context6.next = 9;
                break;
              }

              _context6.next = 6;
              return this.get('setChannel').perform(channel);

            case 6:
              state.setInReplyTo(post);
              _context6.next = 11;
              break;

            case 9:
              _context6.next = 11;
              return this.get('setChannel').perform(this.get('state.publicChannel'));

            case 11:

              this._setCCs(post, conversationRequester);
              this.focusFroalaEditor();

            case 13:
            case 'end':
              return _context6.stop();
          }
        }
      }, _callee6, this);
    })).drop(),

    create: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee7() {
      var model;
      return regeneratorRuntime.wrap(function _callee7$(_context7) {
        while (1) {
          switch (_context7.prev = _context7.next) {
            case 0:
              _context7.prev = 0;
              model = this.get('case');
              _context7.next = 4;
              return this.get('state.create').perform();

            case 4:
              this.get('case.requester.recentCases').reload();
              this.attrs.onCaseCreate(model);
              _context7.next = 12;
              break;

            case 8:
              _context7.prev = 8;
              _context7.t0 = _context7['catch'](0);

              if (_context7.t0 instanceof _errors.InvalidError) {
                _context7.next = 12;
                break;
              }

              throw _context7.t0;

            case 12:
            case 'end':
              return _context7.stop();
          }
        }
      }, _callee7, this, [[0, 8]]);
    })).drop(),

    enqueueReply: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee8() {
      var shouldDeliverViaMessenger, meta;
      return regeneratorRuntime.wrap(function _callee8$(_context8) {
        while (1) {
          switch (_context8.prev = _context8.next) {
            case 0:
              shouldDeliverViaMessenger = this.get('wrappedSelectedChannel.shouldDeliverViaMessenger');
              meta = { shouldDeliverViaMessenger: shouldDeliverViaMessenger };
              _context8.next = 4;
              return this.get('state.enqueueReply').perform(meta);

            case 4:
              this.focusFroalaEditor();
              this.triggerActivity();

            case 6:
            case 'end':
              return _context8.stop();
          }
        }
      }, _callee8, this);
    })),

    reply: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee9() {
      var post;
      return regeneratorRuntime.wrap(function _callee9$(_context9) {
        while (1) {
          switch (_context9.prev = _context9.next) {
            case 0:
              _context9.prev = 0;
              _context9.next = 3;
              return this.get('state.legacyReply').perform(this.get('timeline'));

            case 3:
              this.get('serverClock').restartRunningTick();
              this.focusFroalaEditor();
              this.triggerActivity();
              _context9.next = 8;
              return this.get('fetchNewerPostsAfterReply').perform();

            case 8:
              post = _context9.sent;

              this.addNoteToViewNotesIfInNotesMode(post);
              _context9.next = 16;
              break;

            case 12:
              _context9.prev = 12;
              _context9.t0 = _context9['catch'](0);

              if (_context9.t0 instanceof _errors.InvalidError) {
                _context9.next = 16;
                break;
              }

              throw _context9.t0;

            case 16:
            case 'end':
              return _context9.stop();
          }
        }
      }, _callee9, this, [[0, 12]]);
    })).drop(),

    updateProperties: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee10() {
      return regeneratorRuntime.wrap(function _callee10$(_context10) {
        while (1) {
          switch (_context10.prev = _context10.next) {
            case 0:
              _context10.prev = 0;
              _context10.next = 3;
              return this.get('state').get('update').perform();

            case 3:
              this.get('serverClock').restartRunningTick();
              this.triggerActivity();
              _context10.next = 7;
              return this.get('fetchNewerPostsAfterReply').perform();

            case 7:
              _context10.next = 13;
              break;

            case 9:
              _context10.prev = 9;
              _context10.t0 = _context10['catch'](0);

              if (_context10.t0 instanceof _errors.InvalidError) {
                _context10.next = 13;
                break;
              }

              throw _context10.t0;

            case 13:
            case 'end':
              return _context10.stop();
          }
        }
      }, _callee10, this, [[0, 9]]);
    })).drop(),

    updateAndResend: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee11() {
      return regeneratorRuntime.wrap(function _callee11$(_context11) {
        while (1) {
          switch (_context11.prev = _context11.next) {
            case 0:
              _context11.next = 2;
              return this.get('state.updateAndResend').perform();

            case 2:
            case 'end':
              return _context11.stop();
          }
        }
      }, _callee11, this);
    })).drop(),

    suggestPeople: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee12(address) {
      var mailboxAddresses, trimmedAddress, isAMailboxAddress, selectedPeople, idsToExclude, data, results, isValidEmail, enteredCcIsNotSelectedOrMailboxEmail, autocompleteResultIsNotTheSameAsEnteredCc;
      return regeneratorRuntime.wrap(function _callee12$(_context12) {
        while (1) {
          switch (_context12.prev = _context12.next) {
            case 0:
              mailboxAddresses = this.get('store').peekAll('channel').filterBy('isChannelTypeMailbox').getEach('handle');
              trimmedAddress = address.trim();
              isAMailboxAddress = mailboxAddresses.includes(trimmedAddress);

              if (!(isBlank(trimmedAddress) || isAMailboxAddress)) {
                _context12.next = 5;
                break;
              }

              return _context12.abrupt('return', []);

            case 5:
              _context12.next = 7;
              return (0, _emberConcurrency.timeout)(300);

            case 7:
              selectedPeople = this.get('replyOptions.cc');
              idsToExclude = [].concat(_toConsumableArray(selectedPeople), _toConsumableArray(mailboxAddresses));
              _context12.next = 11;
              return this.get('store').query('identity-autocomplete-email', {
                address: trimmedAddress
              });

            case 11:
              data = _context12.sent;


              // Remove any already selected or mailbox emails from results list
              results = data.filter(function (autocomplete) {
                return !idsToExclude.includes(autocomplete.get('identity.email'));
              });
              isValidEmail = (0, _formatValidations.validateEmailFormat)(trimmedAddress);
              enteredCcIsNotSelectedOrMailboxEmail = !idsToExclude.includes(trimmedAddress);
              autocompleteResultIsNotTheSameAsEnteredCc = !results.mapBy('identity.email').includes(trimmedAddress);


              if (isValidEmail && enteredCcIsNotSelectedOrMailboxEmail && autocompleteResultIsNotTheSameAsEnteredCc) {
                results.unshift(EmberObject.create({
                  isNew: true,
                  identity: {
                    email: trimmedAddress
                  }
                }));
              }

              return _context12.abrupt('return', results);

            case 18:
            case 'end':
              return _context12.stop();
          }
        }
      }, _callee12, this);
    })).restartable(),

    loadMacros: computed.readOnly('state.loadMacrosLazily'),

    typing: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee13(text) {
      var kase;
      return regeneratorRuntime.wrap(function _callee13$(_context13) {
        while (1) {
          switch (_context13.prev = _context13.next) {
            case 0:
              kase = this.get('case');

              if (!isBlank(text)) {
                _context13.next = 4;
                break;
              }

              this._stoppedTyping(kase);

              return _context13.abrupt('return');

            case 4:
              _context13.prev = 4;

              this._startedTyping(kase);
              _context13.next = 8;
              return (0, _emberConcurrency.timeout)(KRE_END_TYPING_DEBOUNCE);

            case 8:
              this._stoppedTyping(kase);
              _context13.next = 13;
              break;

            case 11:
              _context13.prev = 11;
              _context13.t0 = _context13['catch'](4);

            case 13:
              _context13.prev = 13;

              if (this.get('isDestroying') || this.get('case.id') !== kase.get('id')) {
                this._stoppedTyping(kase);
              }
              return _context13.finish(13);

            case 16:
            case 'end':
              return _context13.stop();
          }
        }
      }, _callee13, this, [[4, 11, 13, 16]]);
    })).restartable(),

    fetchNotes: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee14() {
      var notes, context;
      return regeneratorRuntime.wrap(function _callee14$(_context14) {
        while (1) {
          switch (_context14.prev = _context14.next) {
            case 0:
              _context14.prev = 0;
              _context14.next = 3;
              return this.get('store').query('note', {
                parent: this.get('case'),
                limit: 999
              });

            case 3:
              notes = _context14.sent;

              this.set('case.viewNotes', notes.toArray());
              _context14.next = 10;
              break;

            case 7:
              _context14.prev = 7;
              _context14.t0 = _context14['catch'](0);

              if (!Ember.testing && window.Bugsnag) {
                context = (0, _bugsnag.getMetaData)(null, getOwner(this));

                window.Bugsnag.notifyException(_context14.t0, 'Failed to fetch notes', context, 'info');
              }

            case 10:
            case 'end':
              return _context14.stop();
          }
        }
      }, _callee14, this, [[0, 7]]);
    })).restartable(),

    _startedTyping: function _startedTyping(kase) {
      // Messenger shouldn't indicate if an agent/admin is typing an internal note.
      if (!this.get('isTyping') && !this.get('state.isNote')) {
        this.set('isTyping', true);
        this.updatePresenceMeta(kase, { is_typing: true });
        this.sendTypingEvent(kase, 'client-start_typing');
      }
    },
    _stoppedTyping: function _stoppedTyping(kase) {
      if (this.get('isTyping')) {
        this.set('isTyping', false);
        this.updatePresenceMeta(kase, { is_typing: false });
        this.sendTypingEvent(kase, 'client-stop_typing');
      }
    },
    updatePresenceMeta: function updatePresenceMeta(kase) {
      var updates = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var caseChannelName = kase.get('realtimeChannel');
      if (!caseChannelName) {
        return;
      }

      var socket = this.get('socket');
      if (!this.get('socket').hasJoinedChannel(caseChannelName)) {
        return;
      }

      var meta = assign({ last_active_at: new Date().getTime() }, updates);
      socket.push(caseChannelName, 'update-presence-meta', meta);
    },


    // TODO - remove this once Android/iOS clients stop relying on it
    sendTypingEvent: function sendTypingEvent(kase, event) {
      var realtimeChannel = kase.get('realtimeChannel');
      if (!this.get('socket').hasJoinedChannel(realtimeChannel)) {
        return;
      }

      var eventPayload = {
        id: this.get('sessionService.user.id'),
        full_name: this.get('sessionService.user.fullName'),
        avatar: this.get('sessionService.user.avatar')
      };

      this.get('socket').push(realtimeChannel, event, eventPayload);
    },


    createUser: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee15(name, id, isTwitter) {
      var store, roleModel, userObject, email, twitter, user;
      return regeneratorRuntime.wrap(function _callee15$(_context15) {
        while (1) {
          switch (_context15.prev = _context15.next) {
            case 0:
              store = this.get('store');
              _context15.next = 3;
              return store.findRecord('role', CUSTOMER_ROLE_ID);

            case 3:
              roleModel = _context15.sent;
              userObject = {
                role: roleModel,
                fullName: name
              };
              email = void 0, twitter = void 0;

              if (isTwitter) {
                _context15.next = 14;
                break;
              }

              email = store.createRecord('identity-email', {
                isPrimary: true,
                email: id
              });

              userObject.emails = [email];

              _context15.next = 11;
              return store.createRecord('user', userObject).save();

            case 11:
              return _context15.abrupt('return', _context15.sent);

            case 14:
              _context15.next = 16;
              return store.createRecord('user', userObject).save();

            case 16:
              user = _context15.sent;
              _context15.next = 19;
              return store.createRecord('identity-twitter', {
                user: user,
                isPrimary: true,
                screenName: id
              }).save();

            case 19:
              twitter = _context15.sent;


              user.set('twitter', [twitter]);

              return _context15.abrupt('return', user);

            case 22:
            case 'end':
              return _context15.stop();
          }
        }
      }, _callee15, this);
    })).drop(),

    createOrg: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee16(name) {
      var store;
      return regeneratorRuntime.wrap(function _callee16$(_context16) {
        while (1) {
          switch (_context16.prev = _context16.next) {
            case 0:
              store = this.get('store');
              _context16.next = 3;
              return store.createRecord('organization', { name: name }).save();

            case 3:
              return _context16.abrupt('return', _context16.sent);

            case 4:
            case 'end':
              return _context16.stop();
          }
        }
      }, _callee16, this);
    })).drop(),

    getChannel: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee17(user) {
      var userId, store, data;
      return regeneratorRuntime.wrap(function _callee17$(_context17) {
        while (1) {
          switch (_context17.prev = _context17.next) {
            case 0:
              if (!this.get('case.isNew')) {
                _context17.next = 9;
                break;
              }

              userId = user.get('id');
              store = this.get('store');
              _context17.next = 5;
              return store.query('channel', { user_id: userId });

            case 5:
              data = _context17.sent;


              this.set('case.replyChannels', data);
              _context17.next = 11;
              break;

            case 9:
              _context17.next = 11;
              return this.get('case.replyChannels').reload();

            case 11:
              _context17.next = 13;
              return this.get('state.initChannels').perform();

            case 13:

              this.focusFroalaEditor();

            case 14:
            case 'end':
              return _context17.stop();
          }
        }
      }, _callee17, this);
    })).restartable(),

    setChannelWhenRequesterPresent: function setChannelWhenRequesterPresent() {
      var kase = this.get('case');
      var requester = this.get('editedCase.requester');

      if (!requester) {
        return;
      }

      if (kase === this._previousCase && requester === this._previousRequester) {
        return;
      }
      this._previousCase = kase;
      this._previousRequester = requester;

      this.get('getChannel').perform(requester);
      this.set('tabsModel.requester', requester);
    },
    setTabsModelRequester: function setTabsModelRequester() {
      this.set('tabsModel.requester', this.get('editedCase.requester'));
    },
    focusInstantSearch: function focusInstantSearch() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.$('#' + this.get('elementId') + '-kie-instant-input').click();
    },
    findAndClickOnSubjectField: function findAndClickOnSubjectField() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.$('.' + _styles.default['timeline-header-body']).find('span:not(.ember-view)').click();
    },
    focusSubject: function focusSubject() {
      scheduleOnce('afterRender', this, 'findAndClickOnSubjectField');
    },
    scheduleCheckingForEditableActiveElement: function scheduleCheckingForEditableActiveElement(force) {
      var subject = this.get('editedCase.subject');

      var el = document.activeElement;
      var isCurrentElementEditable = el && (el.isContentEditable || el.tagName.toUpperCase() === 'INPUT' || el.tagName.toUpperCase() === 'TEXTAREA');

      var selection = document.getSelection();
      var isDocumentTextSelected = !!(selection.focusOffset - selection.anchorOffset);

      if (!isCurrentElementEditable && !isDocumentTextSelected || force) {
        if (typeof subject === 'string' && !subject.trim().length && this.get('editedCase.requester')) {
          this.focusSubject();
        } else {
          this.focusFroalaEditor();
        }
      }
    },
    focusSubjectOrReply: function focusSubjectOrReply(force) {
      scheduleOnce('afterRender', this, 'scheduleCheckingForEditableActiveElement', force);
    },
    resetEntityParams: function resetEntityParams(doSetOrg, doSetReq) {
      this.set('instantEntityTerm', '');
      this.set('instantEntityResults', null);
      this.set('setOrganizationMode', !!doSetOrg);
      this.set('setRequesterMode', !!doSetReq);
    },
    handleRequesterChangeFailure: function handleRequesterChangeFailure(oldRequester) {
      var state = this.get('state');
      var i18n = this.get('i18n');

      state.setRequester(oldRequester);
      this.set('tabsModel.requester', oldRequester);
      this.setChannelWhenRequesterPresent();
      this.get('notification').error(i18n.t('cases.requester_assignment_failed'));
    },
    applyRequesterAndChannel: function applyRequesterAndChannel(requester) {
      var state = this.get('state');
      state.setRequester(requester);
      this.set('tabsModel.requester', requester);
      this.setChannelWhenRequesterPresent();
    },
    addNoteToViewNotesIfInNotesMode: function addNoteToViewNotesIfInNotesMode(post) {
      if (this.get('state.isNote')) {
        // The case timeline consists of `post`s that can wrap `note`s, `reply`-ies and `activity`-ies.
        post = post.get('firstObject.original.content') || post;
        if (post) {
          this.get('case.viewNotes').addObject(post);
        }
      }
    },


    persistOrgToUser: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee18(user, org) {
      var i18n, opts, message;
      return regeneratorRuntime.wrap(function _callee18$(_context18) {
        while (1) {
          switch (_context18.prev = _context18.next) {
            case 0:
              i18n = this.get('i18n');

              user.set('organization', org);
              opts = { adapterOptions: { setOrganization: true } };
              _context18.prev = 3;
              _context18.next = 6;
              return user.save(opts);

            case 6:

              this.send('setInstantUser', user);

              message = i18n.t('organization.assignment_passed', {
                name: user.get('fullName'),
                org: org.get('name')
              });


              this.get('notification').success(message);
              _context18.next = 15;
              break;

            case 11:
              _context18.prev = 11;
              _context18.t0 = _context18['catch'](3);

              this.get('notification').error(i18n.t('organization.assignment_failed'));
              user.set('organization', null);

            case 15:
            case 'end':
              return _context18.stop();
          }
        }
      }, _callee18, this, [[3, 11]]);
    })).drop(),

    reloadRequester: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee19() {
      var requester;
      return regeneratorRuntime.wrap(function _callee19$(_context19) {
        while (1) {
          switch (_context19.prev = _context19.next) {
            case 0:
              _context19.next = 2;
              return this.get('editedCase.requester');

            case 2:
              requester = _context19.sent;

              if (!(requester && !requester.get('isReloading') && !requester.get('isSaving'))) {
                _context19.next = 6;
                break;
              }

              _context19.next = 6;
              return requester.reload();

            case 6:
            case 'end':
              return _context19.stop();
          }
        }
      }, _callee19, this);
    })).drop(),

    setBrand: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee20(brand) {
      return regeneratorRuntime.wrap(function _callee20$(_context20) {
        while (1) {
          switch (_context20.prev = _context20.next) {
            case 0:
              _context20.next = 2;
              return this.get('state.setBrand').perform(brand);

            case 2:
              this.get('getChannel').perform(this.get('case.requester'));

            case 3:
            case 'end':
              return _context20.stop();
          }
        }
      }, _callee20, this);
    })),

    addExternalNote: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee21(destination) {
      var user, organization, postContent, atMentionsSupported, payload, type, post;
      return regeneratorRuntime.wrap(function _callee21$(_context21) {
        while (1) {
          switch (_context21.prev = _context21.next) {
            case 0:
              user = this.get('case.requester.id');
              organization = this.get('case.requester.organization.id');
              postContent = this.get('postContent');
              atMentionsSupported = this.get('atMentionsSupported');


              if (!atMentionsSupported) {
                postContent = (0, _atMentions.replaceMentionsWithPlainText)(postContent);
              }

              payload = {
                contents: postContent,
                attachmentFileIds: this.get('attachedPostFiles').mapBy('attachmentId').compact()
              };
              type = destination === 'user' ? 'user' : 'organization';
              _context21.prev = 7;
              _context21.next = 10;
              return this.get('store').createRecord('note', payload).save({ adapterOptions: { type: type, user: user, organization: organization } });

            case 10:
              post = _context21.sent;

              if (!this.get('state.arePropertiesEdited')) {
                _context21.next = 14;
                break;
              }

              _context21.next = 14;
              return this.get('state.update').perform();

            case 14:
              this.get('state').resetReplyBox();
              _context21.next = 17;
              return this.get('fetchNewerPostsAfterReply').perform();

            case 17:
              this.addNoteToViewNotesIfInNotesMode(post);
              _context21.next = 24;
              break;

            case 20:
              _context21.prev = 20;
              _context21.t0 = _context21['catch'](7);

              if (_context21.t0 instanceof _errors.InvalidError) {
                _context21.next = 24;
                break;
              }

              throw _context21.t0;

            case 24:
            case 'end':
              return _context21.stop();
          }
        }
      }, _callee21, this, [[7, 20]]);
    })),

    submitCreate: function submitCreate() {
      if (this.get('submitDisabled')) {
        return;
      }

      this.get('create').perform();
    },
    submitReply: function submitReply() {
      if (this.get('submitReplyDisabled')) {
        return;
      }
      this._stoppedTyping(this.get('case'));
      this.get('enqueueReply').perform();
    },
    submitUpdate: function submitUpdate() {
      if (this.get('submitDisabled')) {
        return;
      }

      this._stoppedTyping(this.get('case'));
      this.get('updateProperties').perform();
    },
    _markNotificationAsRead: function _markNotificationAsRead() {
      var notificationId = Number(this.get('routing.router.router.state.fullQueryParams.notificationId'));

      if (notificationId && !isNaN(notificationId)) {
        this.get('notificationCenter').markAsRead(notificationId);
      }
    },
    _defaultToReplyingToTheLastMessageFromTheCustomer: function _defaultToReplyingToTheLastMessageFromTheCustomer() {
      var launchDarkly = this.get('launchDarkly');

      if (!launchDarkly.get('release-reply-all-by-default')) {
        return;
      }

      if (this.get('isCaseClosed') || this.get('isCaseTrashed')) {
        return;
      }

      var lastMessageFromACustomer = this.get('timeline.lastMessageFromACustomer');
      var isNote = this.get('state.isNote');
      var shouldDeliverViaMessenger = this.get('wrappedSelectedChannel.shouldDeliverViaMessenger');
      var state = this.get('state');

      if (!isNote && !shouldDeliverViaMessenger && lastMessageFromACustomer && lastMessageFromACustomer.get('sourceChannel') && lastMessageFromACustomer.get('sourceChannel.channelType') === 'MAIL') {
        if (!state.isCCActive) {
          var conversationRequester = this.get('case.requester');
          state.setInReplyTo(lastMessageFromACustomer);
          this._setCCs(lastMessageFromACustomer, conversationRequester);
        }
      }

      this.send('dispatch', 'inferStateFromLatestPosts', this.get('state.posts'));
    },
    _setCCs: function _setCCs(post, requester) {
      if (post.get('original.postType') !== 'message') {
        return;
      }

      var state = this.get('state');
      var mailboxAddresses = [];
      var ccs = [];

      var recipients = post.get('original.recipients');
      var postCreatorEmails = post.get('creator.emails') ? post.get('creator.emails').mapBy('email') : [];
      var postOriginalEmail = post.get('original.email');
      var requesterEmailObjects = requester.get('emails');
      var requesterEmails = requesterEmailObjects ? requesterEmailObjects.map(function (emailIdentity) {
        return emailIdentity.get('email');
      }) : [];
      var primaryRequesterEmail = requesterEmailObjects ? requesterEmailObjects.filterBy('isPrimary').get('firstObject.email') : null;

      this.get('brands').forEach(function (brand) {
        mailboxAddresses.push.apply(mailboxAddresses, _toConsumableArray(brand.get('mailboxes').mapBy('address')));
      });

      state.setCCs([]);

      if (recipients) {
        var recipientsToBeAddedAsCCs = recipients.filter(function (recipient) {
          return !postCreatorEmails.includes(recipient.get('identity.email')) && !mailboxAddresses.includes(recipient.get('identity.email'));
        });

        ccs = recipientsToBeAddedAsCCs.getEach('identity.email');
      }

      if (!requesterEmails.includes(postOriginalEmail)) {
        if (primaryRequesterEmail) {
          ccs.push(primaryRequesterEmail);
        }
        if (!ccs.includes(postOriginalEmail) && !mailboxAddresses.includes(postOriginalEmail)) {
          ccs.push(postOriginalEmail);
        }
      }

      state.setCCs(ccs);
    },


    // Actions
    actions: {
      dispatch: function dispatch(method) {
        var _get2;

        for (var _len2 = arguments.length, rest = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          rest[_key2 - 1] = arguments[_key2];
        }

        if (method === 'setPostContent') {
          var _get;

          (_get = this.get('typing')).perform.apply(_get, _toConsumableArray(rest));
        }
        (_get2 = this.get('state'))[method].apply(_get2, _toConsumableArray(rest));
      },
      setImageUploadStatus: function setImageUploadStatus(status) {
        this.set('isImageUploading', status);
      },
      setTwitterType: function setTwitterType(_ref2) {
        var type = _ref2.type;

        this.get('state').setTwitterType(type);
      },
      setNoteDestination: function setNoteDestination(destination) {
        this.get('state').setNoteDestination(destination);
        debounce(this, 'focusSubjectOrReply', FOCUS_DEBOUNCE_TIME);
      },
      setFilter: function setFilter(filter) {
        this.get('onQueryParamsUpdate')({ filter: filter, postId: null, noteId: null });
      },
      onAttachFiles: function onAttachFiles(files) {
        var _this3 = this;

        var service = this.get('uploadService');
        var attachedPostFiles = this.get('attachedPostFiles');
        var channel = this.get('channel');
        var onUploadAttachmentStart = function onUploadAttachmentStart() {
          var _get3;

          return (_get3 = _this3.get('state')).addAttachment.apply(_get3, arguments);
        };
        var onUploadAttachment = function onUploadAttachment() {
          var _get4;

          return (_get4 = _this3.get('state')).updateAttachments.apply(_get4, arguments);
        };
        files.forEach(function (file) {
          return service.get('uploadFile').perform(file, attachedPostFiles, channel, onUploadAttachmentStart, onUploadAttachment).catch(function () {/* Swallow this as this isn't an error we can handle */});
        });
      },
      openNote: function openNote(event) {
        if (event) {
          event.stopImmediatePropagation();
          event.preventDefault();
        }

        this.get('openNote').perform();
      },
      quote: function quote(post) {
        var sanitizedContent = _npmHe.default.escape(post.get('contents'));
        var quotedContent = sanitizedContent.split('\n').map(function (line) {
          return '> ' + line;
        }).join('<br>');
        this.get('state').appendPostContent('<br>' + quotedContent);
        this.focusFroalaEditor();
      },
      copyLink: function copyLink(url) {
        (0, _copyToClipboard.default)(window.location.protocol + '//' + window.location.host + url);
        this.get('notification').success(this.get('i18n').t('generic.copied_to_clipboard'));
      },
      openReply: function openReply(force, event) {
        var publicChannel = this.get('state.publicChannel');

        if (publicChannel) {
          this.get('setChannel').perform(publicChannel, force, event);
        } else {
          this.set('state.isNote', false);
          this.get('state.initChannels').perform();
        }
      },
      setChannel: function setChannel(wrappedChannel) {
        this.get('setChannel').perform(wrappedChannel.get('channel'));
      },
      submit: function submit() {
        var destination = this.get('noteDestination.id');
        if (this.get('isBeingCreated')) {
          this.submitCreate();
        } else if (this.get('state').hasReply()) {
          switch (destination) {
            case 'user':
            case 'org':
              this.get('addExternalNote').perform(destination);
              break;
            default:
              this.submitReply();
          }
        } else {
          this.submitUpdate();
        }
      },
      sendAndSet: function sendAndSet(selectedState) {
        this.send('dispatch', 'setStatus', selectedState);
        this.send('submit');
      },


      editSubject: function editSubject() {
        this.focusSubject();
      },

      handleTabbingFromSubject: function handleTabbingFromSubject(event) {
        var subject = this.get('editedCase.subject');
        this.get('state').setSubject(subject);

        if ([_keycodes.tab, _keycodes.enter].includes(event.keyCode)) {
          event.preventDefault();
          this.focusFroalaEditor();
        }
      },
      handleTabbingFromReply: function handleTabbingFromReply(event) {
        if (event.keyCode === _keycodes.tab) {
          event.preventDefault();

          if (this.get('editedCase.requester')) {
            this.focusSubject();
          } else {
            scheduleOnce('afterRender', this, 'focusInstantSearch');
          }
        }
      },
      handleInstantEntityTabbing: function handleInstantEntityTabbing(key) {
        if (key === 'TAB') {
          this.focusSubjectOrReply(true);
        }
      },
      setOrganizationModeOn: function setOrganizationModeOn() {
        this.resetEntityParams(true, false);
      },
      setOrganizationModeOff: function setOrganizationModeOff() {
        this.resetEntityParams();
        this.focusSubjectOrReply(true);
      },
      setRequesterModeOn: function setRequesterModeOn(event) {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }
        this.resetEntityParams(false, true);
      },
      setRequesterModeOff: function setRequesterModeOff() {
        this.resetEntityParams();
        this.focusSubjectOrReply(true);
      },
      setInstantUser: function setInstantUser(user) {
        var _this4 = this;

        var caseExists = this.get('case.id');
        var oldRequester = this.get('editedCase.requester');
        var i18n = this.get('i18n');

        if (typeof user === 'string') {
          user = user.trim();

          // Create User flow
          var isTwitter = (0, _formatValidations.validateTwitterHandleFormat)(user);

          if (isTwitter) {
            user = user.slice(1);
          }
          var name = void 0;
          var id = user;

          var username = id;

          if (!isTwitter) {
            username = id.slice(0, id.indexOf('@'));
          }

          if (username.indexOf('+') !== -1) {
            username = username.slice(0, username.indexOf('+'));
          }

          var _username$split = username.split('.'),
              _username$split2 = _slicedToArray(_username$split, 2),
              firstName = _username$split2[0],
              lastName = _username$split2[1];

          name = capitalize(firstName);
          if (lastName && lastName.trim().length) {
            name += ' ' + capitalize(lastName);
          }

          this.get('createUser').perform(name, id, isTwitter).then(function (user) {
            _this4.get('notification').success(i18n.t('cases.new_conversation.user_created'));
            _this4.applyRequesterAndChannel(user);
            if (caseExists) {
              _this4.get('case').save({ adapterOptions: { setRequester: true } }).catch(function () {
                return _this4.handleRequesterChangeFailure(oldRequester);
              });
            }
          }).finally(function () {
            _this4.resetEntityParams();
            _this4.focusSubjectOrReply();
          });
        } else {
          this.focusSubjectOrReply();
          this.applyRequesterAndChannel(user);
          this.resetEntityParams();
          if (caseExists) {
            this.get('case').save({ adapterOptions: { setRequester: true } }).catch(function () {
              return _this4.handleRequesterChangeFailure(oldRequester);
            });
          }
        }
      },
      setInstantOrg: function setInstantOrg(org) {
        var _this5 = this;

        var i18n = this.get('i18n');
        if (typeof org === 'string') {
          org = capitalize(org.trim());

          // Create Org flow
          var savePromise = this.get('createOrg').perform(org);
          savePromise.then(function (org) {
            _this5.get('notification').success(i18n.t('organization.created'));

            var user = _this5.get('editedCase.requester.content') || _this5.get('editedCase.requester');
            _this5.get('persistOrgToUser').perform(user, org);
          }).finally(function () {
            _this5.resetEntityParams();
          });
        } else {
          this.resetEntityParams();
          var user = this.get('editedCase.requester.content') || this.get('editedCase.requester');
          this.get('persistOrgToUser').perform(user, org);
        }
      },
      updateOrgRemovalState: function updateOrgRemovalState(value, org) {
        this.set('unsetOrgInProgress', value);
        this.set('removedOrg', org);

        var selectedNoteDestinationIsOrg = this.get('selectedNoteDestination.id') === 'org';

        if (!value && !org && selectedNoteDestinationIsOrg) {
          this.get('state').setNoteDestination(this.get('noteDestinations.firstObject'));
        }
      },
      breachChange: function breachChange(e, value) {
        this.set('isBreached', value);
      },
      requesterPresenceChanged: function requesterPresenceChanged(data) {
        var id = this.get('editedCase.requester.id');
        var metas = data[id] && data[id].metas;
        var isOnline = !!(metas && metas.length);

        var wasOnline = this.get('isRequesterOnline');
        var hasGoneOffline = wasOnline && !isOnline;
        if (hasGoneOffline) {
          this.get('reloadRequester').perform();
        }

        this.set('isRequesterOnline', isOnline);
      },
      preserveTimer: function preserveTimer(totalSeconds, isBillable) {
        this.get('state').setTimerValue(totalSeconds, isBillable);
      },
      resetProperties: function resetProperties() {
        this.get('state').resetSidebar();
      },
      mostRecentFetched: function mostRecentFetched(posts) {
        this._defaultToReplyingToTheLastMessageFromTheCustomer();
        this.send('dispatch', 'inferStateFromLatestPosts', posts);
      }
    }
  });
});